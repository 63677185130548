@import 'npm:bootstrap/dist/css/bootstrap.min.css';
@import './nano/nanogallery.min.css';

@font-face {
	font-family: "dinnextlight";
	src: url(dinnextlight.otf) format("truetype");
}

@font-face {
	font-family: 'dinnextlight';
  	src: url('dinnextlight.eot'); /* IE9 Compat Modes */
  	src: url('dinnextlight.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
         url('dinnextlight.woff') format('woff'), /* Pretty Modern Browsers */
         url('dinnextlight.ttf')  format('truetype'); /* Safari, Android, iOS */
}

html {
	background-color: #1a1a1a;
}

body {
	background-color: #1a1a1a;
	font-family: "Helvetica Neue", Helvetica, Arial, sans-serif !important;
}

#fader {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
	z-index: 999998;
    pointer-events: none;
	background-color: #1a1a1a;
	animation-duration: 300ms;
    animation-timing-function: ease-in-out;
}

noscript {
	position: absolute;
	z-index: 999999;
	color: #fff;
}

@keyframes fade-out {
    from { opacity: 1 }
      to { opacity: 0 }
}

@keyframes fade-in {
    from { opacity: 0 }
      to { opacity: 1 }
}

#fader.fade-out {
    opacity: 0;
    animation-name: fade-out;
}

#fader.fade-in {
    opacity: 1;
    animation-name: fade-in;
}

header {
	font-family: dinnextlight;
	color: #9235E8;
	font-size: 60px;
	text-align: center;
	line-height: 1.0em;
	margin: 15px 0;
}

.italic {
	font-style: italic;
}

.bold {
	font-weight: bold;
}

.cap {
	font-weight: 600;
}

.navbar-inverse {
	background-color: transparent;
	border: 0;
	margin-bottom: 5px;
}

.navbar-nav>li>a {
	padding-top: 7px;
	padding-bottom: 7px;
	text-align: center;
	color: #fff;
}

.navbar-inverse .navbar-nav>li>a {
	color: #fff;
}

.navbar-inverse .navbar-nav>.active>a, .navbar-inverse .navbar-nav>.active>a:hover, .navbar-inverse .navbar-nav>.active>a:focus {
	color: #fff;
	background-color: #9235E8;
}

@media (min-width: 768px){
    .navbar-nav{
        float: none;
        margin: 0 auto;
        display: table;
        table-layout: fixed;
    }

    .navbar-nav>li>a {
		border-top: 1px solid #fff;
		border-bottom: 1px solid #fff;
	}
}

.center {
	text-align: center;
}

.content {
	color: #fff;
	margin-bottom: 20px;
}

.welcomeimage {
	margin-top: 20px;
}

.container .content {
	margin-top: 10px;
}

.content .text {
	text-align: left;
}

.content .kop {
	font-family: dinnextlight;
	font-size: 40px;
	color: #9235E8;
	border-bottom: 1px solid #9235E8;
	text-align: left;
}

.content .kop-small {
	font-family: dinnextlight;
	font-size: 24px;
	color: #9235E8;
	text-align: left;
}

.content .kop-smaller {
	font-family: dinnextlight;
	font-size: 20px;
	color: #9235E8;
	text-align: left;
}

.content .subkop {
	font-family: dinnextlight;
	font-size: 24px;
	color: #fff;
	text-align: left;
	margin-top: 10px;
}

.content img {
    max-width: 100%;
}

.photocaption {
	text-align: center;
	margin-top: 2px;
	font-size: 18px;
}

#gallery .row {
	margin-bottom: 20px;
}

#pbCloseBtn:before {
	font-size: 20px;
	padding: 5px 0 0 5px;
}

#pbCloseBtn:hover:before {
	padding: 5px 0 0 5px;
}

p.smaller {
	font-size: 0.8em;
}

.projectsep {
	border-bottom: 1px solid #9235E8;
}

.jssora03l, .jssora03r, .jssora03ldn, .jssora03rdn {
	position: absolute;
	cursor: pointer;
	display: block;
    background: url(../img/a03.png) no-repeat;
    overflow:hidden;
}

textarea.form-control {
	resize: vertical;
}

.form-control {
	border-radius: 0;
	padding: 4px 6px;
	height: 25px;
}

.form-group {
	margin-bottom: 5px;
}

#refresh {
	cursor: pointer;
}

.jssora03l { background-position: -3px -33px; left: 8px; }
.jssora03r { background-position: -63px -33px; right: 8px; }
.jssora03l:hover { background-position: -123px -33px; }
.jssora03r:hover { background-position: -183px -33px; }
.jssora03ldn { background-position: -243px -33px; }
.jssora03rdn { background-position: -303px -33px; }

.underline {
	text-decoration: underline;
}

.circ {
	background-color: #9235E8 !important;
}

hr {
	border: 0.5px solid #9235E8;
}

hr.thick {
	border: 1.5px solid #9235E8;
}

table {
	color: #fff;
	width: 100%;
}

table thead tr td {
	padding: 10px 0;
}

.no-hpadding {
	padding-left: 0;
	padding-right: 0;
}

.slider_container {
	position: relative;
	width: 350px;
	height: 300px;
	background-color: #000;
	overflow: hidden;
}

.slider_container .slides {
	cursor: move;
	position: absolute;
	left: 0;
	top: 0;
	width: 350px;
	height: 300px;
	overflow: hidden;
}

.slider_container .arrow {
	width: 55px;
	height: 55px;
	top: 123px;
}

.timeline {
	position: relative;
	padding: 20px 0;
	list-style: none;
}

.timeline:before {
	content: '';
	position: absolute;
	top: 0;
	bottom: 0;
	width: 4px;
	background: #ddd;
	left: 50%;
	margin-left: -2px;
}

.timeline-item {
	position: relative;
	margin: 20px 0;
}

.timeline-icon {
	position: absolute;
	top: 0;
	left: 50%;
	width: 20px;
	height: 20px;
	margin-left: -10px;
	background: #873be0;
	border-radius: 50%;
	border: 4px solid #ffffff;
}

.timeline-content {
	position: relative;
	width: 46%;
	padding: 10px 20px;
	background: #353535;
	border-radius: 5px;
	box-shadow: 0 2px 4px rgba(0,0,0,0.1);
}

.timeline-item:nth-child(odd) .timeline-content {
	left: 0;
}

.timeline-item:nth-child(even) .timeline-content {
	left: 54%;
}

.full-w {
	width: 100%;
}